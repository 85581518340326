import React from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarItem from './SidebarItem'
import OffoodLogo from '../brand/OffoodLogo';
import { useAuth } from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Alert from '../utils/Alert';

function Sidebar({ menuList, isClickable = false, count = false, title = null, description = null }) {
    const navigate = useNavigate();
    const { logout } = useAuth();    
    const { t } = useTranslation();
    const controlledRestaurantName = useSelector((state) => state.admin.controlledRestaurantName);
    const controlledCompanyName = useSelector((state) => state.admin.controlledCompanyName);
    const { isAuthenticated } = useAuth();

    return (
        <div id="sidebar">
            <div className='sidebar-header'>
                <OffoodLogo width='100px' onClick={() => navigate('/')} />
            </div>
            {(title || description) && <div className="sidebar-text-span">
                { title && <h3 className="sidebar-title">{title}</h3> }     
                { description && <div className="p3 sidebar-description text-light">{description}</div> }                    
            </div>}
            {controlledRestaurantName && <Alert className='mb-2'>
                <span dangerouslySetInnerHTML={{ __html: t('dashboard.admin.controlledRestaurant', { name: controlledRestaurantName }) }} />
            </Alert>}
            {controlledCompanyName && <Alert className='mb-2'>
                <span dangerouslySetInnerHTML={{ __html: t('dashboard.admin.controlledCompany', { name: controlledCompanyName }) }} />
            </Alert>}
            <div className="sidebar-list">
                {menuList.map((item, index) => (
                    <SidebarItem key={index} item={item} isClickable={isClickable} count={count && index + 1} />
                ))}
            </div>
            {isAuthenticated && <SidebarItem className='mt-auto' item={{ tKey: 'dashboard.logout.', onClick: logout }} isClickable={isClickable} />}
        </div>
    );
}

export default Sidebar;